import * as styles from './ProductGrid.module.css'

import React from 'react'

import * as Typography from 'components/typography'
import cubeConPrintImage from './images/cube-con-2023-print-summary-photo.jpg'
import cubeWillOutliveMagicImage from './images/cube-will-outlive-magic-playmat.jpg'
import ProductPreview from 'src/components/shop/ProductPreview'

const ProductGrid: React.FC = () => {
  return (
    <div className={styles.container}>
      <Typography.PrimaryHeading>Shop</Typography.PrimaryHeading>
      <ProductPreview
        image={cubeWillOutliveMagicImage}
        title="Cube Will Outlive Magic Playmat"
        description="Our first playmat is a custom design with gold foil accents proclaiming the long life of Cube."
        price={50}
        href="cube-will-outlive-magic-playmat"
        soldOut
      />
      <ProductPreview
        image={cubeConPrintImage}
        title="Cube Con 2023 Map Print"
        description="Celebrate the biggest Cube event of 2023 with a high quality, digital print of the Lucky Paper Cube Map, with all 48 featured cubes from the second Cube Con highlighted and labeled with their designer and geographic origin."
        price={50}
        href="cube-con-2023-print"
        soldOut
      />
    </div>
  )
}

export default ProductGrid
