import * as styles from './ProductPreview.module.css'

import React from 'react'

import { Link } from 'gatsby'
import ProductLayout from './ProductLayout'
import CallToAction from './CallToAction'

interface Props {
  image: string
  title: string
  description: string
  price: number
  href: string
  soldOut?: boolean
}

const ProductPreview: React.FC<Props> = (props) => {
  const { image, title, description, price, href, soldOut = false } = props

  return (
    <Link to={href}>
      <ProductLayout
        imageColumn={<img className={styles.image} src={image} alt={title} />}
      >
        <div className={styles.info}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.description}>{description}</div>
          {soldOut ? (
            <div className={styles.soldOutNotice}>Sold Out</div>
          ) : (
            <div className={styles.buyInfo}>
              <div>${price}</div>
              <div>—</div>
              <CallToAction>More Information</CallToAction>
            </div>
          )}
        </div>
      </ProductLayout>
    </Link>
  )
}

export default ProductPreview
